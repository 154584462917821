import { FormType } from "@simplyk/common";

import {
  FreeFormsDonationDesktop,
  FreeFormsDonationMobile,
  FreeFormsTicketingDesktop,
  FreeFormsTicketingMobile,
} from "../../../constants/params";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useFormV2Context } from "../context/FormV2Context";

export const useUtmForm = (): string => {
  const { type } = useFormV2Context();
  const { isPhoneScreen } = useMediaQuery();

  const utmMap = {
    [FormType.DonationForm]: {
      mobile: FreeFormsDonationMobile,
      desktop: FreeFormsDonationDesktop,
    },
    [FormType.Ticketing]: {
      mobile: FreeFormsTicketingMobile,
      desktop: FreeFormsTicketingDesktop,
    },
  };

  return utmMap[type][isPhoneScreen ? "mobile" : "desktop"];
};
