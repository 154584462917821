import { FC } from "react";

import { Box } from "@mui/material";
import { HomePageFreePlatformUrl, UrlBuilder } from "@simplyk/common";

import { AmplitudeEvents } from "../../../constants/amplitude";
import { UTM_SOURCE_PARAM_KEY } from "../../../constants/params";
import { useLocaleContext } from "../../../contexts/LocaleContext";
import { useAmplitude } from "../../../hooks/amplitude/useAmplitude";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useFormV2Context } from "../context/FormV2Context";

import { Zeffy as ZeffyLogo } from "@/icons/other";

type Props = {
  utm?: string;
  formStep: 1 | 2;
};

export const FormV2ZeffyLogo: FC<Props> = ({ utm = "", formStep }) => {
  const { isSmallScreen } = useMediaQuery();
  const { locale } = useLocaleContext();
  const { logAmplitudeEvent } = useAmplitude();
  const { formObject, type, formCategory } = useFormV2Context();

  const url = UrlBuilder({
    pathname: HomePageFreePlatformUrl[locale],
    searchParams: new URLSearchParams({ [UTM_SOURCE_PARAM_KEY]: utm }),
  }).build();

  const handleClickLogo = () => {
    logAmplitudeEvent(AmplitudeEvents.LearnMoreAboutZeffyViewed, {
      form_type: type,
      form_category: formCategory,
      organization_country: formObject.organization?.country,
      form_step: formStep === 1 ? "Step 1 - Selection" : "Step 2 - Checkout",
    });
  };

  return (
    <Box
      component="a"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      onClick={handleClickLogo}
      sx={(theme) => ({
        height: isSmallScreen ? "20px" : "30px",
        width: isSmallScreen ? "66.061px" : "99px",
        fill: theme.palette.surface.form.moderate,
        position: isSmallScreen ? "static" : "fixed",
        bottom: isSmallScreen ? "unset" : 15,
        right: !isSmallScreen ? theme.spacing(6) : "unset",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textDecoration: "none",
        "&:hover": {
          opacity: 0.8,
        },
      })}
      data-test="zeffy-logo-wrapper"
    >
      <ZeffyLogo />
    </Box>
  );
};
