import { FC } from "react";

import { Box, SxProps, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FormType, getDatePlusNMonths } from "@simplyk/common";
import { DateTime } from "luxon";

import { formatDate, formatDateTime, isEventOneDay } from "../../../../components/DateFormatter/helpers";
import { useLocaleContext } from "../../../../contexts/LocaleContext";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { useTranslate } from "../../../../hooks/useTranslate";
import { useFormV2Context } from "../../context/FormV2Context";

interface DateTextProps {
  start: GraphQL_Date;
  end: GraphQL_Date;
  ticketingIsClosed: boolean;
  sx?: SxProps;
}

export const TicketingV2DateSummaryText: FC<DateTextProps> = ({ start, end, ticketingIsClosed, sx }) => {
  const { t } = useTranslate();
  const theme = useTheme();
  const { isSmallScreen } = useMediaQuery();
  const { isoLocale } = useLocaleContext();
  const { isAuction } = useFormV2Context(FormType.Ticketing);

  const eventDate = new Date(start);

  const variant = isSmallScreen ? "body2" : "body1";
  const bold = (children: React.ReactNode) => (
    <Typography variant={variant} sx={{ color: theme.palette.text.form.moderate, fontWeight: 600 }}>
      {children}
    </Typography>
  );

  const normal = (children: React.ReactNode) => (
    <Typography variant={variant} sx={{ color: theme.palette.text.form.moderate }}>
      {children}
    </Typography>
  );

  /*
   * Renders an event date happening on a single day in less than a week
   * ex:
   * 3 days left!
   * Jan 23 @ 5:00 PM - 8:00 PM
   */
  const renderImminentFormattedSingleDate = (numberOfDaysUntilEvent: number) => {
    const dateFormatOptions: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      ...(shouldIncludeYear(eventDate) ? { year: "numeric" } : {}),
    };

    const formattedStartDate = formatDate(start, isoLocale, dateFormatOptions);
    const formattedStartTime = formatDateTime(start, isoLocale);
    const formattedEndTime = formatDateTime(end, isoLocale);

    return (
      <>
        {bold(t("ticketing", "dateSummary.daysLeft", { count: numberOfDaysUntilEvent }))}
        {normal(
          t("ticketing", "dateSummary.imminentEventDate", {
            date: formattedStartDate,
            startTime: formattedStartTime,
            endTime: formattedEndTime,
          })
        )}
      </>
    );
  };

  /*
   * Renders an event date spanning multiple days starting in less than a week
   * ex:
   * 3 days left!
   * Jan 23 @ 5:00 PM to Jan 26 @ 8:00 PM
   */
  const renderImminentFormattedMultiDate = (numberOfDaysUntilEvent: number) => {
    const dateFormatOptions: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      ...(shouldIncludeYear(eventDate) ? { year: "numeric" } : {}),
    };

    const formattedStartDate = formatDate(start, isoLocale, dateFormatOptions);
    const formattedStartTime = formatDateTime(start, isoLocale);
    const formattedEndDate = formatDate(end, isoLocale, dateFormatOptions);
    const formattedEndTime = formatDateTime(end, isoLocale);

    return (
      <>
        {bold(t("ticketing", "dateSummary.daysLeft", { count: numberOfDaysUntilEvent }))}
        {normal(
          t("ticketing", "dateSummary.imminentMultiDayEventDate", {
            startDate: formattedStartDate,
            startTime: formattedStartTime,
            endDate: formattedEndDate,
            endTime: formattedEndTime,
          })
        )}
      </>
    );
  };

  /*
   * Renders an event date happening on a single day starting in more than a week
   * ex:
   * Thursday, February 23
   * 5:00 PM - 8:00 PM
   */
  const renderFormattedSingleDate = () => {
    const dateFormatOptions: Intl.DateTimeFormatOptions = {
      weekday: "long",
      month: "long",
      day: "numeric",
      ...(shouldIncludeYear(eventDate) ? { year: "numeric" } : {}),
    };

    const formattedStartDate = formatDate(start, isoLocale, dateFormatOptions);
    const formattedStartTime = formatDateTime(start, isoLocale);
    const formattedEndTime = formatDateTime(end, isoLocale);

    return (
      <>
        {bold(formattedStartDate)}
        {normal(
          <>
            {formattedStartTime} - {formattedEndTime}
          </>
        )}
      </>
    );
  };

  /*
   * Renders an event date spanning multiple days starting in more than a week
   * ex:
   * Thursday, February 23 at 5:00 PM
   * to Sunday, February 26 at 8:00 PM
   */
  const renderFormattedMultiDate = () => {
    const dateFormatOptions: Intl.DateTimeFormatOptions = {
      weekday: isSmallScreen ? undefined : "long",
      month: "long",
      day: "numeric",
      ...(shouldIncludeYear(eventDate) ? { year: "numeric" } : {}),
    };

    const formattedStartDate = formatDate(start, isoLocale, dateFormatOptions);
    const formattedStartTime = formatDateTime(start, isoLocale);
    const formattedEndDate = formatDate(end, isoLocale, dateFormatOptions);
    const formattedEndTime = formatDateTime(end, isoLocale);

    return (
      <>
        {bold(
          t("ticketing", "dateSummary.multiDayEventStart", {
            formattedMultiDayStart: formattedStartDate,
            formattedMultiDayStartTime: formattedStartTime,
          })
        )}
        {normal(
          t("ticketing", "dateSummary.multiDayEventEnd", {
            formattedMultiDayEnd: formattedEndDate,
            formattedMultiDayEndTime: formattedEndTime,
          })
        )}
      </>
    );
  };

  /*
   ** Renders the closing date for the auction (the start date is not used)
   * ex:
   * Auction closes on
   * Jan 23 @ 5:00 PM
   */
  const renderAuctionDate = () => {
    const dateFormatOptions: Intl.DateTimeFormatOptions = {
      weekday: "short",
      month: "short",
      day: "numeric",
      ...(shouldIncludeYear(eventDate) ? { year: "numeric" } : {}),
    };

    const formattedEndDate = formatDate(end, isoLocale, dateFormatOptions);
    const formattedEndTime = formatDateTime(end, isoLocale);

    return (
      <>
        {bold(t("ticketing", "dateSummary.auctionClosing"))}
        {normal(
          t("ticketing", "dateSummary.auctionEndDate", {
            date: formattedEndDate,
            time: formattedEndTime,
          })
        )}
      </>
    );
  };

  const renderEventDate = () => {
    if (isAuction) {
      return renderAuctionDate();
    }

    const numberOfDaysUntilEvent = Math.floor(DateTime.fromJSDate(eventDate).diffNow("days").days);
    const isEventSingleDay = isEventOneDay(start, end);

    const isImminent = !ticketingIsClosed && numberOfDaysUntilEvent < 7 && numberOfDaysUntilEvent >= 0;

    if (isImminent) {
      return isEventSingleDay
        ? renderImminentFormattedSingleDate(numberOfDaysUntilEvent)
        : renderImminentFormattedMultiDate(numberOfDaysUntilEvent);
    }

    return isEventSingleDay ? renderFormattedSingleDate() : renderFormattedMultiDate();
  };

  return (
    <Box data-test="ticketing-v2-date-summary-text" sx={sx}>
      {renderEventDate()}
    </Box>
  );
};

const shouldIncludeYear = (eventDate: Date) => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const eventYear = eventDate.getFullYear();

  const threeMonthsFromNow = getDatePlusNMonths(now, 3);

  const isPast = eventDate < now;
  const isMoreThanThreeMonthsAway = eventDate > threeMonthsFromNow;
  const isNextYear = eventYear > currentYear;

  return isPast || (isNextYear && isMoreThanThreeMonthsAway);
};
