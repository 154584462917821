import { FC } from "react";

import { Stack } from "@mui/material";
import { FormType } from "@simplyk/common";

import { useFormV2Context } from "../../context/FormV2Context";

import { TicketingV2AddressSummary } from "./TicketingV2AddressSummary";
import { TicketingV2DateSummary } from "./TicketingV2DateSummary";

export const TicketingV2LocationAndDate: FC = () => {
  const { hasDate, hasLocation } = useFormV2Context(FormType.Ticketing);

  if (!hasDate && !hasLocation) {
    return null;
  }

  return (
    <Stack gap={1}>
      {hasDate && <TicketingV2DateSummary />}
      {hasLocation && <TicketingV2AddressSummary />}
    </Stack>
  );
};
