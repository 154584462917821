import { FC } from "react";

import { Typography } from "@/design-system/Typography";
import { useFormV2Context } from "@/features/FormV2/context/FormV2Context";
import { STEP_TO_ELEMENT_ID } from "@/features/LiveFormEditor/AnimatePreview/Arrow/ArrowContext";
import { DonationFormCategory, FormType } from "@/gql-types";
import { useTranslate } from "@/hooks/useTranslate";

export const FormV2Title: FC = () => {
  const { formObject, type } = useFormV2Context();
  const { localeField } = useFormV2Context();
  const { t } = useTranslate();

  const isPeerToPeerIndividualCampaignPage =
    type === FormType.DonationForm &&
    formObject.category === DonationFormCategory.PeerToPeerCampaign &&
    !formObject.isPrimaryTeam &&
    !formObject.isPrimaryCampaign;

  const title = isPeerToPeerIndividualCampaignPage
    ? t("donationForm", "peerToPeer.individualFundraiser.title", {
        title: localeField?.title,
      })
    : localeField?.title;

  return <StatelessFormV2Title title={title} />;
};

export type StatelessFormV2TitleProps = {
  title: string;
};

export const StatelessFormV2Title: FC<StatelessFormV2TitleProps> = ({ title }) => {
  return (
    <Typography variant="h4" vibe="text-form-intense" data-test="form-title" id={STEP_TO_ELEMENT_ID["title"]}>
      {title}
    </Typography>
  );
};
