import { FC } from "react";

import { Box } from "@mui/material";

import { useBanner } from "../../../components/Banner/useBanner";
import FormBanner from "../../../components/FormBanner/FormBanner";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { usePreviewContext } from "../../LiveFormEditor/LivePreview/context/PreviewContext";
import { GHOST_PREVIEW_OPACITY } from "../../LiveFormEditor/LivePreview/ghostPreview.constant";
import { useIsGhostPreview } from "../../LiveFormEditor/LivePreview/useIsGhostPreview";
import { useFormV2Context } from "../context/FormV2Context";

export type FormV2BannerProps = {
  onLoadingComplete?: () => void;
};

export const FormV2Banner: FC<FormV2BannerProps> = ({ onLoadingComplete }) => {
  const { formObject, type } = useFormV2Context();
  const { isPhoneScreen } = useMediaQuery();
  const { width: bannerWidth } = useBanner(formObject, type);
  const { isPreview } = usePreviewContext();
  const isGhostPreview = useIsGhostPreview("FormV2Banner");

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        alignSelf: "center",
        ...(isPreview && {
          // Hide corner that goes out of the iframe
          borderTopRightRadius: `${theme.radius(1)}`,
          borderTopLeftRadius: `${theme.radius(1)}`,
        }),
      })}
      data-test="ticketing-v2-container-banner-step1"
      id="ticketing-v2-banner"
    >
      <Box
        sx={{
          width: { xs: "100%", sm: bannerWidth, md: "100%" },
          height: "auto",
          backgroundColor: (theme) => theme.palette.surface.form.moderate, // For loading
          borderRadius: (theme) => (!isPhoneScreen ? theme.radius(1) : 0),
          overflow: "hidden",
          ...(isGhostPreview && {
            opacity: GHOST_PREVIEW_OPACITY,
          }),
          ["& img"]: {
            // Force the image to take the full width
            // We need to proceed this way to avoid affecting v1 forms
            width: "100%",
          },
        }}
      >
        <FormBanner form={formObject} formType={type} onLoadingComplete={onLoadingComplete} />
      </Box>
    </Box>
  );
};
