import { useEffect, useState } from "react";

import { useTheme } from "@mui/material";

import { rgbStringToRgbaString } from "../../../../helpers/colors";

export const useFetchHeartsAnimation = (rgbHeartsColor?: string) => {
  const theme = useTheme();
  const [heartsAnimation, setHeartsAnimation] = useState<Record<string, unknown> | null>(null);

  useEffect(() => {
    const fetchHeartsAnimationData = async () => {
      let animationData = await import("./hearts-animation.json");

      let animationDataString = JSON.stringify(animationData);

      const jsonFormattedColor = rgbStringToRgbaString(rgbHeartsColor || theme.palette.surface.brand.moderate);

      animationDataString = animationDataString.replace(
        /"k":\s*\[\d+(\.\d+)?,\s*\d+(\.\d+)?,\s*\d+(\.\d+)?,\s*1\]/g,
        `"k": ${jsonFormattedColor}`
      );

      animationData = JSON.parse(animationDataString);

      setHeartsAnimation(animationData);
    };
    fetchHeartsAnimationData();
  }, [rgbHeartsColor, theme.palette.surface.brand.moderate]);

  return heartsAnimation;
};
