import { FC } from "react";

import { alpha, Box, Stack, SxProps, Theme, useTheme } from "@mui/material";

import { Typography } from "../../../components/design-system/Typography";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { usePreviewContext } from "../../LiveFormEditor/LivePreview/context/PreviewContext";
import { useFormV2Context } from "../context/FormV2Context";

import Image from "@/components/Image/Image";

export const FormV2LogoChip = () => {
  const { formObject } = useFormV2Context();

  const { isPreview, placeholders } = usePreviewContext();

  return (
    <StatelessFormV2LogoChip
      imageSrc={formObject.logoUrl ?? undefined}
      withFallbackImage={isPreview && placeholders.isLogo}
      organizationName={formObject.organization!.name}
    />
  );
};

export type StatelessFormV2LogoChipProps = {
  imageSrc?: string;
  withFallbackImage?: boolean;
  organizationName: string;
};

export const StatelessFormV2LogoChip: FC<StatelessFormV2LogoChipProps> = ({
  imageSrc,
  withFallbackImage = true,
  organizationName,
}) => {
  const theme = useTheme();
  const { isSmallScreen } = useMediaQuery();

  const imageSx: SxProps<Theme> = {
    height: { xs: 30, md: 40 },
    width: { xs: 30, md: 40 },
    borderRadius: 0.5,
    flexShrink: 0,
  };

  return (
    <Stack
      id="ticketing-v2-logo"
      data-test="form-v2-logo-chip"
      display="inline-flex"
      direction="row"
      alignItems="center"
      gap={1}
      sx={(theme) => ({
        minHeight: { xs: 30, md: 40 },
        padding: { xs: theme.spacing(0.5, 1), md: theme.spacing(1, 2) },
        backgroundColor: theme.palette.surface.neutral.white,
        borderRadius: 1,
        boxShadow: `2px 2px 16px ${alpha(theme.palette.text.form.moderate, 0.1)}`,
      })}
    >
      {/* Logo or Placeholder */}
      {imageSrc ? (
        <Box sx={{ ...imageSx }}>
          <Image
            src={imageSrc}
            alt="Logo"
            data-test="form-header-logo"
            width="100%"
            height="100%"
            resolution={100}
            style={{
              objectFit: "contain",
              borderRadius: theme.radius(0.5),
            }}
          />
        </Box>
      ) : withFallbackImage ? (
        <Box sx={{ ...imageSx, backgroundColor: "grey.200" }} />
      ) : null}

      {/* Organization Name */}
      <Typography variant={isSmallScreen ? "subtitle2" : "h6"} vibe="text-form-intense" sx={{ whiteSpace: "nowrap" }}>
        {organizationName}
      </Typography>
    </Stack>
  );
};
