class InternalUrlBuilder {
    pathname;
    hash;
    searchParams;
    constructor(pathname, hash, searchParams) {
        this.pathname = pathname;
        this.hash = hash;
        this.searchParams = searchParams;
    }
    appendSearchParams(searchParams) {
        const newSearchParams = new URLSearchParams(this.searchParams);
        Object.entries(searchParams).forEach(([key, value]) => {
            if (value) {
                newSearchParams.set(key, value);
            }
            else {
                newSearchParams.delete(key);
            }
        });
        return new InternalUrlBuilder(this.pathname, this.hash, newSearchParams);
    }
    removeSearchParams(...keys) {
        const newSearchParams = new URLSearchParams(this.searchParams);
        keys.forEach((key) => {
            newSearchParams.delete(key);
        });
        return new InternalUrlBuilder(this.pathname, this.hash, newSearchParams);
    }
    build() {
        const finalHash = this.hash ? `#${encodeURIComponent(this.hash)}` : "";
        const finalSearchParams = this.searchParams.toString() ? `?${this.searchParams}` : "";
        return `${this.pathname}${finalHash}${finalSearchParams}`;
    }
}
export const UrlBuilder = ({ pathname, hash, searchParams, }) => {
    return new InternalUrlBuilder(pathname, hash, searchParams ?? new URLSearchParams());
};
