import { FC, useMemo, useState, useEffect } from "react";

import { Box, Stack, Skeleton, useTheme, LinearProgress, linearProgressClasses, Typography } from "@mui/material";
import {
  AvailablePaymentCurrency,
  FormType,
  formatCurrencyAmountWithoutCents,
  getDollarValue,
  OrganizationCountry,
} from "@simplyk/common";
import dynamic from "next/dynamic";

const Lottie = dynamic(() => import("react-lottie"), { ssr: false });

import { useLocaleContext } from "../../../contexts/LocaleContext";
import { useGetFormVolumeQuery } from "../../../gql/queries/generated/formQuery";
import { rgbStringToRgbaString } from "../../../helpers/colors";
import { useTranslate } from "../../../hooks/useTranslate";
import { usePreviewContext } from "../../LiveFormEditor/LivePreview/context/PreviewContext";
import { useIsGhostPreview } from "../../LiveFormEditor/LivePreview/useIsGhostPreview";

import { FlipCard } from "./FlipCard";

import { GHOST_PREVIEW_OPACITY } from "@/features/LiveFormEditor/LivePreview/ghostPreview.constant";
type FormV2ThermometerProps = {
  country: OrganizationCountry;
  thermometerTarget: number;
  formId: string;
  type: FormType;
};

export const FormV2Thermometer: FC<FormV2ThermometerProps> = ({ country, thermometerTarget, formId, type }) => {
  const max = thermometerTarget;
  const currency = country === OrganizationCountry.Canada ? AvailablePaymentCurrency.Cad : AvailablePaymentCurrency.Usd;
  const { isPreview } = usePreviewContext();
  const { t } = useTranslate();
  const theme = useTheme();
  const { isoLocale } = useLocaleContext();
  const isGhostPreview = useIsGhostPreview("FormV2Thermometer");

  const { data: volumeData, loading } = useGetFormVolumeQuery({
    variables: { formInput: { id: formId, formType: type } },
  });
  const progressValue = isPreview
    ? (max || 0) * 0.3 // In preview mode, show 30% of target
    : volumeData?.getFormVolume.object || 0;

  const formattedCurrentCollectedAmount = useMemo(() => {
    return formatCurrencyAmountWithoutCents(getDollarValue(progressValue || 0), isoLocale, currency);
  }, [isoLocale, progressValue, currency]);

  const formattedTargetedAmount = useMemo(() => {
    return formatCurrencyAmountWithoutCents(getDollarValue(max ?? 0), isoLocale, currency);
  }, [currency, isoLocale, max]);

  const percentage = max ? Math.round((progressValue / max) * 100) : 0;

  const frontText = t("ticketing", "thermometer", {
    value: formattedCurrentCollectedAmount,
    goal: formattedTargetedAmount,
  });

  const backText = useMemo(() => {
    if (percentage < 50) {
      return t("ticketing", "thermometer.promote.1");
    }
    if (percentage < 80) {
      return t("ticketing", "thermometer.promote.2");
    }
    if (percentage < 100) {
      return t("ticketing", "thermometer.promote.3");
    }
    return t("ticketing", "thermometer.promote.4");
  }, [percentage, t]);

  const [heartsAnimation, setHeartsAnimation] = useState<Record<string, unknown> | null>(null);

  useEffect(() => {
    const fetchHeartsAnimationData = async () => {
      let animationData = await import("./hearts.json");
      let animationDataString = JSON.stringify(animationData);
      const jsonFormattedColor = rgbStringToRgbaString(theme.palette.surface.brand.moderate);
      animationDataString = animationDataString.replace(
        /"k":\s*\[\d+(\.\d+)?,\s*\d+(\.\d+)?,\s*\d+(\.\d+)?,\s*1\]/g,
        `"k": ${jsonFormattedColor}`
      );
      animationData = JSON.parse(animationDataString);
      setHeartsAnimation(animationData);
    };
    fetchHeartsAnimationData();
  }, [theme.palette.surface.brand.moderate]);

  if (loading) {
    return <Skeleton variant="rectangular" height={60} animation="wave" />;
  }

  // Front Content
  const frontContent = (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.palette.surface.form.quiet,
        padding: theme.spacing(1.5),
        borderRadius: "8px",
        overflow: "hidden",
        position: "relative",
        ...(isGhostPreview && {
          opacity: GHOST_PREVIEW_OPACITY,
        }),
      }}
    >
      {progressValue >= (max || 0) && (
        <Lottie
          options={{ animationData: heartsAnimation, autoplay: true }}
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            bottom: 0,
            width: 140,
            height: 140,
          }}
        />
      )}
      {max && (
        <Stack spacing={0.5}>
          <Stack direction="row" alignItems="center">
            <Typography
              variant="subtitle2"
              sx={{
                color: theme.palette.text.form.moderate,
              }}
            >
              {frontText}
            </Typography>
            <Typography variant="subtitle2" sx={{ color: theme.palette.text.form.supershy, marginLeft: "auto" }}>
              {`${percentage}%`}
            </Typography>
          </Stack>
          <LinearProgress
            color="primary"
            value={progressValue > max ? 100 : Math.max(percentage, 1)}
            sx={{
              width: "100%",
              height: 12,
              borderRadius: 10,
              [`& .${linearProgressClasses.bar}`]: {
                borderRadius: 5,
              },
            }}
            variant="determinate"
          />
        </Stack>
      )}
    </Box>
  );

  // Back Content
  const backContent = (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.palette.surface.form.quiet,
        padding: theme.spacing(1.5),
        borderRadius: "8px",
        ...(isGhostPreview && {
          opacity: GHOST_PREVIEW_OPACITY,
        }),
      }}
    >
      {max && (
        <Stack spacing={0.5}>
          <Stack direction="row" alignItems="center">
            <Typography
              variant="subtitle2"
              sx={{
                color: theme.palette.text.form.moderate,
              }}
            >
              {backText}
            </Typography>
            <Typography variant="subtitle2" sx={{ color: theme.palette.text.form.supershy, marginLeft: "auto" }}>
              {`${percentage}%`}
            </Typography>
          </Stack>
          <LinearProgress
            color="primary"
            value={progressValue > max ? 100 : Math.max(percentage, 1)}
            sx={{
              width: "100%",
              height: 12,
              borderRadius: 10,
              [`& .${linearProgressClasses.bar}`]: {
                borderRadius: 5,
              },
            }}
            variant="determinate"
          />
        </Stack>
      )}
    </Box>
  );

  return <FlipCard front={frontContent} back={backContent} height="60px" />;
};
