import { FC, ReactNode, useEffect, useRef, useState } from "react";

import { Box, Stack, SxProps, useTheme } from "@mui/material";
import ResizeObserver from "resize-observer-polyfill";

import { Divider } from "@/design-system/Divider";
import { FormV2ZeffyLogo } from "@/features/FormV2/sharedComponents/FormV2ZeffyLogo";
import { useHasValueChanged } from "@/hooks/useHasValueChanged";
import { useMediaQuery } from "@/hooks/useMediaQuery";

export type BaseFormV2Step1LayoutProps = {
  slots: {
    banner?: ReactNode;
    info: ReactNode;
    order: ReactNode;
    checkoutFooter?: ReactNode;
    divider?: ReactNode;
  };
  contextDrawerHeight: number;
  isOneColumn?: boolean;
  isEmbed?: boolean;
  showCheckoutFooter?: boolean;
  utm?: string;
};

const ONE_COLUMN_MAX_WIDTH = 597;

const checkIfElementIsScrollingVertically = (element: Element) => {
  return element.scrollHeight > element.clientHeight;
};

const useIsStickyColumnScrolling = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [isScrolling, setIsScrolling] = useState(true);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const element = entries[0].target;

      setIsScrolling(checkIfElementIsScrollingVertically(element));
    });

    const mutationObserver = new MutationObserver(() => {
      if (ref.current) {
        setIsScrolling(checkIfElementIsScrollingVertically(ref.current));
      }
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
      mutationObserver.observe(ref.current, { childList: true, subtree: true });
    }

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
    };
  }, []);

  return {
    ref,
    isScrolling,
  };
};

/**
 * This base layout handles the 2 columns of all forms.
 * Not meant to be used directly, but rather extended by each form.
 */
export const BaseFormV2Step1Layout: FC<BaseFormV2Step1LayoutProps> = ({
  slots,
  contextDrawerHeight,
  isOneColumn = false,
  isEmbed = false,
  showCheckoutFooter = false,
  utm = "",
}) => {
  const theme = useTheme();
  const { isSmallScreen } = useMediaQuery();

  const hasBanner = Boolean(slots.banner);
  const [animateCheckoutFooter, watchedShowCheckoutFooter] = useHasValueChanged(showCheckoutFooter);

  const { ref: infoColumnRef, isScrolling: isInfoColumnScrolling } = useIsStickyColumnScrolling();
  const { ref: orderColumnRef, isScrolling: isOrderColumnScrolling } = useIsStickyColumnScrolling();

  const isOneColumnOrEmbed = isOneColumn || isEmbed;

  const noc = <T,>(value: T) => (isOneColumnOrEmbed ? undefined : value);

  const renderStickyColumn = (
    ref: React.RefObject<HTMLDivElement | null>,
    content: React.ReactNode,
    sx: SxProps,
    isScrolling: boolean
  ) => {
    return (
      <Box
        ref={ref}
        display={{ md: noc("flex") }}
        flex={{ md: noc("1 0 0%") }}
        height={{ md: noc(`calc(100dvh - ${contextDrawerHeight}px)`) }}
        paddingBlock={{
          md: noc(6),
          lg: noc(8),
          xl: noc(10),
        }}
        sx={{
          ...sx,
          alignItems: { md: noc(isScrolling ? "flex-start" : "center") },
          boxSizing: "border-box",
          overflow: { md: noc("auto") },
          scrollbarWidth: "none",
          zIndex: 2,
        }}
      >
        {content}
      </Box>
    );
  };

  const renderAnimatedCheckoutFooter = () => {
    const hidden = !watchedShowCheckoutFooter && !animateCheckoutFooter;

    return (
      <Box
        data-test="form-v2-checkout-footer"
        position={{ xs: "fixed", md: noc("sticky") }}
        // The sticky footer should always stick to 40px from the bottom of the screen
        bottom={{ xs: 0, md: noc(theme.spacing(-1)), lg: noc(theme.spacing(-3)), xl: noc(theme.spacing(-5)) }}
        left={0}
        right={0}
        marginBlockStart={{ md: hidden ? 0 : noc(2) }}
        padding={{ xs: hidden ? 0 : 2, md: noc(0) }}
        sx={{
          animation: animateCheckoutFooter
            ? watchedShowCheckoutFooter
              ? "fade-slide-in 0.3s ease-in-out both"
              : "fade-slide-out 0.3s ease-in-out both"
            : undefined,
          backgroundColor: { xs: theme.palette.surface.form.supershy, md: noc("transparent") },
          borderTop: { xs: `1px solid ${theme.palette.surface.form.quiet}`, md: noc("none") },
          opacity: hidden ? 0 : undefined,
          height: hidden ? 0 : undefined,
          zIndex: 1000,
          ["@keyframes fade-slide-in"]: {
            from: { translate: "0 20px", opacity: 0 },
            to: { translate: "0 0", opacity: 1 },
          },
          ["@keyframes fade-slide-out"]: {
            from: { translate: "0 0", opacity: 1 },
            to: { translate: "0 20px", opacity: 0 },
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: isOneColumnOrEmbed ? ONE_COLUMN_MAX_WIDTH : undefined,
            marginInline: isOneColumnOrEmbed ? "auto" : undefined,
            paddingInline: isOneColumnOrEmbed ? 2 : undefined,
          }}
        >
          {slots.checkoutFooter}
        </Box>
      </Box>
    );
  };

  return (
    <Stack
      gap={{ xs: 0, md: noc(4) }}
      justifyContent={{ md: isOneColumn ? "center" : undefined }}
      minHeight={{ md: isOneColumn ? `calc(100dvh - ${contextDrawerHeight}px)` : undefined }}
    >
      <Stack
        gap={noc(2)}
        width="100%"
        maxWidth={isOneColumnOrEmbed ? ONE_COLUMN_MAX_WIDTH : undefined}
        marginInline={isOneColumnOrEmbed ? "auto" : undefined}
      >
        {(isSmallScreen || isOneColumn) && !isEmbed && slots.banner}
        <Stack
          direction={{ md: isOneColumnOrEmbed ? "column" : "row" }}
          gap={{
            xs: 2,
            md: noc(0),
          }}
          padding={{ xs: theme.spacing(hasBanner ? 0 : 4, 2, 0, 2), md: noc(0) }}
        >
          {!isEmbed &&
            renderStickyColumn(
              infoColumnRef,
              slots.info,
              {
                paddingInlineStart: { md: noc(4), lg: noc(10), xl: noc(20) },
                paddingInlineEnd: { md: noc(1.5), lg: noc(2) },
              },
              isInfoColumnScrolling
            )}
          {(isOneColumn || isSmallScreen) && !isEmbed && (
            <>{slots.divider || <Divider vibe={theme.palette.border.form.quiet} sx={{ width: "100%" }} />}</>
          )}
          {renderStickyColumn(
            orderColumnRef,
            <Stack width="100%">
              {slots.order}
              {!isSmallScreen && !isOneColumnOrEmbed && renderAnimatedCheckoutFooter()}
            </Stack>,
            {
              paddingInlineStart: { md: noc(1.5), lg: noc(2) },
              paddingInlineEnd: { md: noc(4), lg: noc(10), xl: noc(20) },
            },
            isOrderColumnScrolling
          )}
        </Stack>
      </Stack>
      {!isEmbed && (
        <Box
          position={{ md: noc("absolute") }}
          display="flex"
          justifyContent="center"
          paddingBlock={{ xs: 2, md: noc(0) }}
          zIndex={1}
        >
          <FormV2ZeffyLogo utm={utm} formStep={1} />
        </Box>
      )}
      {(isSmallScreen || isOneColumnOrEmbed) && (
        <>
          {/* Keep space in the page for the sticky checkout button */}
          {watchedShowCheckoutFooter && <Box sx={{ height: 75, visibility: "hidden" }} />}
          {renderAnimatedCheckoutFooter()}
        </>
      )}
    </Stack>
  );
};
