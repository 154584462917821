import { FC, useState } from "react";

import { Box, Stack, useTheme } from "@mui/material";
import { FormType } from "@simplyk/common";

import { Icon } from "../../../../components/design-system/Icon";
import { Typography } from "../../../../components/design-system/Typography";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { useTranslate } from "../../../../hooks/useTranslate";
import { GHOST_PREVIEW_OPACITY } from "../../../LiveFormEditor/LivePreview/ghostPreview.constant";
import { useIsGhostPreview } from "../../../LiveFormEditor/LivePreview/useIsGhostPreview";
import { useFormV2Context } from "../../context/FormV2Context";
import { parseAddress } from "../../helpers/parseAddress";

import { Location } from "@/icons/outlined";

export const TicketingV2AddressSummary: FC = () => {
  const { isSmallScreen } = useMediaQuery();
  const { t } = useTranslate();
  const theme = useTheme();
  const { formObject: ticketing, isAuction } = useFormV2Context(FormType.Ticketing);
  const [shouldUnderline, setShouldUnderline] = useState(false);
  const isGhostPreview = useIsGhostPreview("TicketingV2AddressSummary");

  const parsedAddress = ticketing.address && parseAddress(ticketing.address);

  if (!parsedAddress) {
    return null;
  }

  const latitude = ticketing.coordinates?.latitude;
  const longitude = ticketing.coordinates?.longitude;

  const variant = isSmallScreen ? "body2" : "body1";

  const googleMapsLink = latitude && longitude ? `https://www.google.com/maps?q=${latitude},${longitude}` : null;

  const withLink = (children: React.ReactNode) => {
    return googleMapsLink ? (
      <a
        href={googleMapsLink}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none", cursor: "pointer" }}
        onMouseEnter={() => setShouldUnderline(true)}
        onMouseLeave={() => setShouldUnderline(false)}
      >
        {children}
      </a>
    ) : (
      <>{children}</>
    );
  };

  return withLink(
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      sx={{
        ...(isGhostPreview && {
          opacity: GHOST_PREVIEW_OPACITY,
        }),
      }}
      data-test="ticketing-v2-address-summary"
      id="ticketing-v2-address-summary"
    >
      {/* Location Icon */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: 48,
          height: 48,
          borderRadius: "9.6px",
          border: "1.44px solid",
          borderColor: theme.palette.border.form.quiet,
          flexShrink: 0,
        }}
      >
        <Icon size="large" style={{ height: "30px", width: "30px" }} vibe="text-form-moderate">
          <Location />
        </Icon>
      </Box>

      {/* Location Text */}
      <Stack data-test="ticketing-v2-address-summary-text" sx={{ flexGrow: 1 }}>
        <Typography
          variant={variant}
          sx={{
            color: theme.palette.text.form.moderate,
            fontWeight: 600,
            textDecoration: "underline",
            textDecorationColor: shouldUnderline ? theme.palette.text.form.moderate : "transparent",
            transition: "text-decoration-color 200ms ease-in",
          }}
        >
          {isAuction ? t("ticketing", "auction.pickupLocation") : parsedAddress.street}
        </Typography>

        <Typography variant={variant} sx={{ color: theme.palette.text.form.moderate }}>
          {isAuction ? ticketing.address : parsedAddress.cityStateZip}
        </Typography>
      </Stack>
    </Stack>
  );
};
