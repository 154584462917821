import { FC, ReactNode, useState } from "react";

import { Box } from "@mui/material";

interface FlipCardProps {
  front: ReactNode;
  back: ReactNode;
  height: string;
}

export const FlipCard: FC<FlipCardProps> = ({ front, back, height }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <Box
      onClick={() => setIsFlipped(!isFlipped)}
      sx={{
        perspective: "1000px",
        height,
        position: "relative",
        width: "100%",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          transition: "transform 0.4s",
          transformStyle: "preserve-3d",
          transform: isFlipped ? "rotateX(180deg)" : "none",
        }}
      >
        {/* Front Side */}
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden",
          }}
        >
          {front}
        </Box>
        {/* Back Side */}
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backfaceVisibility: "hidden",
            transform: "rotateX(180deg)",
          }}
        >
          {back}
        </Box>
      </Box>
    </Box>
  );
};
