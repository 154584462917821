import { FC, ReactNode, useContext } from "react";

import { useTheme } from "@mui/material";

import { HeartsAnimationContext } from "../../context/heartsAnimationContext";
import { ButtonWithHeartAnimation } from "../../sharedComponents/ButtonWithHeartAnimation/ButtonWithHeartAnimation";

import { ArrowRight } from "@/icons/outlined";

interface CheckoutButtonProps {
  onSubmit: () => void;
  canSubmit: boolean;
  label: ReactNode;
  showArrow?: boolean;
}

export const CheckoutButton: FC<CheckoutButtonProps> = ({ onSubmit, canSubmit, label, showArrow = true }) => {
  const { shouldDisplayHeartsAnimation } = useContext(HeartsAnimationContext);
  const theme = useTheme();

  return (
    <ButtonWithHeartAnimation
      onClick={onSubmit}
      disabled={!canSubmit}
      label={
        <span style={{ display: "inline-flex", alignItems: "center", gap: 4 }}>
          {label}
          {showArrow && <ArrowRight width={20} height={20} />}
        </span>
      }
      showAnimation={shouldDisplayHeartsAnimation}
      shouldLoop={false}
      data-test="form-v2-step1-submit-button"
      sx={{
        boxShadow: {
          md: `1px 1px 16px ${theme.palette.text.form.quiet.replace("hsl", "hsla").replace(")", ", 0.9)")}`,
        },
      }}
    />
  );
};
