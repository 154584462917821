import { forwardRef } from "react";

import Box, { BoxProps } from "@mui/material/Box";
import { alpha, useTheme } from "@mui/material/styles";

export type AdaptableTileProps = BoxProps & {
  forceInlined?: boolean;
};

export const AdaptableTile = forwardRef<HTMLDivElement, AdaptableTileProps>(
  ({ children, sx, forceInlined, ...props }, ref) => {
    const theme = useTheme();

    return (
      <Box
        ref={ref}
        {...props}
        width="100%"
        padding={{ md: forceInlined ? 0 : 3 }}
        sx={{
          ...sx,
          backgroundColor: { md: forceInlined ? undefined : theme.palette.background.paper },
          borderRadius: { md: forceInlined ? undefined : theme.radius(1) },
          boxShadow: { md: forceInlined ? undefined : `2px 2px 16px ${alpha(theme.palette.text.form.moderate, 0.1)}` },
        }}
      >
        {children}
      </Box>
    );
  }
);

AdaptableTile.displayName = "AdaptableTile";

export type TileProps = BoxProps;

export const Tile = forwardRef<HTMLDivElement, TileProps>(({ children, sx, ...props }, ref) => {
  const theme = useTheme();

  return (
    <Box
      ref={ref}
      {...props}
      width="100%"
      padding={3}
      sx={{
        ...sx,
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.radius(1),
        boxShadow: `2px 2px 16px ${alpha(theme.palette.text.form.moderate, 0.1)}`,
      }}
    >
      {children}
    </Box>
  );
});

Tile.displayName = "Tile";
