import { FC, useContext } from "react";

import { Box, Stack, SxProps, Theme, useTheme } from "@mui/material";
import { FormCategory, FormType } from "@simplyk/common";

import { FrontendTicketingContext } from "../../../contexts/FrontendTicketingContext";
import { useFormV2Context } from "../context/FormV2Context";
import { TicketingV2LocationAndDate } from "../sharedComponents/TicketingV2LocationAndDate/TicketingV2LocationAndDate";

import { FormV2Banner } from "./FormV2Banner";
import { FormV2Description } from "./FormV2Description";
import { FormV2LogoChip } from "./FormV2LogoChip";
import { FormV2Thermometer } from "./FormV2Thermometer";

import Image from "@/components/Image/Image";
import { Chip } from "@/design-system/Chip/Chip";
import { AdaptableTile } from "@/features/FormV2/sharedComponents/Tile/Tile";
import { FormV2Title } from "@/features/FormV2/Step1/FormV2Title";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { useTranslate } from "@/hooks/useTranslate";
import { FrontendFormContext } from "@/src/contexts/FrontendFormContext";
import { DonationFormOutput } from "@/types/trpc";

export type FormV2SummaryProps = {
  onLoadingComplete?: () => void;
  hideLogoChip?: boolean;
  hideBanner?: boolean;
  forceInlined?: boolean;
};

export const FormV2Summary: FC<FormV2SummaryProps> = ({
  onLoadingComplete,
  hideBanner,
  hideLogoChip,
  forceInlined,
}) => {
  const theme = useTheme();
  const { formObject, hasBanner, description, type, isMembership, formOrganization } = useFormV2Context();

  const { t } = useTranslate();
  const { hasADate, ticketingIsClosed } = useContext(FrontendTicketingContext);
  const { isSmallScreen } = useMediaQuery();
  const { isEmbed } = useContext(FrontendFormContext);

  const shouldDisplayAddress = Boolean(type === FormType.Ticketing && formObject.address);
  const shouldDisplayDateComponent = hasADate;
  const target = type === FormType.Ticketing ? formObject.thermometerTarget : formObject.target;

  const logoContainerStyles: SxProps<Theme> = {
    height: { xs: 30, md: 42 },
    width: { xs: 30, md: 42 },
    borderRadius: 1,
    mr: 1,
    flexShrink: 0,
  };

  const peerToPeerChipLabel = (type: FormType, formObject: DonationFormOutput) => {
    if (type === FormType.DonationForm && formObject.category === FormCategory.PeerToPeerCampaign) {
      if (formObject.isPrimaryCampaign) {
        return "peerToPeer.chip.generalCampaign";
      } else if (formObject.isPrimaryTeam) {
        return "peerToPeer.chip.TeamCampaign";
      } else {
        return "peerToPeer.chip.FundraiserCampaign";
      }
    }
    return "peerToPeer.chip.generalCampaign";
  };

  return (
    <AdaptableTile forceInlined={forceInlined}>
      <Stack position="relative" alignItems="flex-start" gap={2}>
        {isSmallScreen && !hasBanner && !isEmbed && !hideLogoChip && <FormV2LogoChip />}

        {!isSmallScreen && hasBanner && !hideBanner && <FormV2Banner onLoadingComplete={onLoadingComplete} />}

        {/* Organization Banner (mobile) */}
        {hasBanner && isSmallScreen && !isEmbed && !hideLogoChip && <FormV2LogoChip />}

        {hideLogoChip && formObject.logoUrl && (
          <Box sx={{ ...logoContainerStyles, overflow: "hidden" }}>
            <Image
              src={formObject.logoUrl}
              alt="Logo"
              data-test="form-header-logo"
              width="100%"
              height="100%"
              resolution={100}
              style={{
                objectFit: "contain",
              }}
            />
          </Box>
        )}

        {/* Title */}
        <FormV2Title />

        <Stack
          width="100%"
          alignItems="flex-start"
          spacing={{
            xs: 2,
            md: 3,
          }}
        >
          {/* Closed Status */}
          {type === FormType.Ticketing && ticketingIsClosed && (
            <Chip
              vibe="danger"
              variant="outlined"
              label={t("common", isMembership ? "closed" : "eventClosed")}
              data-test="closed-status"
            />
          )}

          {/* Peer to Peer Campain Type: General, Team, Fundraiser */}
          {type === FormType.DonationForm && formObject.category === FormCategory.PeerToPeerCampaign && (
            <Chip
              vibe="brand"
              variant="outlined"
              label={t("donationForm", `${peerToPeerChipLabel(type, formObject)}`)}
              data-test="peer-to-peer-campaign-type"
              sx={{
                backgroundColor: `${theme.palette.surface.brand.quiet} !important`,
                color: `${theme.palette.text.form.supershy} !important`,
                borderColor: `${theme.palette.border.brand.quiet} !important`,
              }}
            />
          )}

          {/* Thermometer */}
          {Boolean(target) && (
            <FormV2Thermometer
              country={formOrganization.country}
              thermometerTarget={target || 0}
              formId={formObject.id}
              type={type}
            />
          )}

          {/* Event And Location */}
          {(shouldDisplayAddress || shouldDisplayDateComponent) && <TicketingV2LocationAndDate />}

          {/* Description */}
          {description && <FormV2Description />}
        </Stack>
      </Stack>
    </AdaptableTile>
  );
};
