import { FunctionComponent, memo, PropsWithChildren } from "react";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { FormType } from "@simplyk/common";
import dynamic from "next/dynamic";

import { DonationFormOutput, TicketingOutput } from "@/types/trpc";

const Banner = dynamic(() => import("../Banner/Banner"), { ssr: false });

const PREFIX = "FormBanner";

const classes = {
  bannerContainer: `${PREFIX}-bannerContainer`,
};

const StyledGrid = memo(
  styled(Grid)(() => ({
    [`&&.${classes.bannerContainer}`]: {
      maxHeight: 600,
      width: "100%",
    },
  }))
);

type FormBannerProps = {
  form: TicketingOutput | DonationFormOutput;
  formType: FormType;
  onLoadingComplete?: () => void;
  roundedBorders?: boolean;
};

const FormBanner: FunctionComponent<PropsWithChildren<FormBannerProps>> = ({
  form,
  formType,
  onLoadingComplete,
  roundedBorders,
}) => {
  const hasBanner = Boolean(form.bannerUrl || form.bannerVideoUrl);

  if (!hasBanner) {
    return null;
  }

  return (
    <StyledGrid item xs={12} className={classes.bannerContainer}>
      <Banner form={form} context={formType} onLoadingComplete={onLoadingComplete} roundedBorders={roundedBorders} />
    </StyledGrid>
  );
};

export default FormBanner;
