import { FC } from "react";

import { Box, Stack, SxProps, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { formatDate } from "../../../../components/DateFormatter/helpers";
import { useLocaleContext } from "../../../../contexts/LocaleContext";

interface Props {
  start: GraphQL_Date;
  sx?: SxProps;
}

export const TicketingV2DateSummarySquare: FC<Props> = ({ start, sx }) => {
  const theme = useTheme();
  const { isoLocale } = useLocaleContext();

  const month = formatDate(start, isoLocale, { month: "short" });
  const dayNumber = formatDate(start, isoLocale, { day: "numeric" });

  return (
    <Stack
      sx={{
        ...sx,
        width: 48,
        height: 48,
        borderRadius: "9.6px",
        overflow: "hidden",
        border: "1px solid",
        borderColor: theme.palette.border.form.quiet,
        flexShrink: 0,
      }}
      data-test="ticketing-v2-date-summary-square"
    >
      {/* Month */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50%"
        sx={{
          backgroundColor: theme.palette.border.form.quiet,
        }}
      >
        <Typography variant="body1" sx={{ lineHeight: 1, color: theme.palette.text.form.moderate }}>
          {month}
        </Typography>
      </Box>

      {/* Day number */}
      <Box display="flex" justifyContent="center" alignItems="center" height="50%">
        <Typography variant="body1" sx={{ lineHeight: 1, fontWeight: 600, color: theme.palette.text.form.moderate }}>
          {dayNumber}
        </Typography>
      </Box>
    </Stack>
  );
};
