import { FC, ReactNode, useContext } from "react";

import { Box, SxProps, Theme } from "@mui/material";
import dynamic from "next/dynamic";

import { Button, ButtonVariant } from "../../../../components/design-system/Button";
import { HeartsAnimationContext } from "../../context/heartsAnimationContext";

import { useFetchHeartsAnimation } from "./useFetchHeartsAnimation";

type Props = {
  onClick: () => void;
  disabled?: boolean;
  label: ReactNode;
  showAnimation?: boolean;
  "data-test"?: string;
  isLoading?: boolean;
  endIcon?: ReactNode;
  variant?: ButtonVariant;
  sx?: SxProps<Theme>;
  onMouseEnter?: () => void;
  rgbHeartsColor?: string;
  shouldLoop?: number | boolean | undefined;
};

const Lottie = dynamic(() => import("react-lottie"), { ssr: false });

export const ButtonWithHeartAnimation: FC<Props> = ({
  onClick,
  disabled = false,
  label,
  showAnimation = false,
  "data-test": dataTest,
  endIcon,
  variant = "filled",
  sx = {},
  onMouseEnter,
  rgbHeartsColor,
  shouldLoop = false,
  isLoading = false,
}) => {
  const heartsAnimation = useFetchHeartsAnimation(rgbHeartsColor);
  const { animationKey } = useContext(HeartsAnimationContext);

  const handleOnMouseEnter = () => {
    onMouseEnter?.();
  };

  return (
    <Button
      sx={{
        ...(variant === "filled" && {
          backgroundColor: (theme) => `${theme.palette.surface.brand.intense} !important`,
        }),
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        ...sx,
      }}
      variant={variant}
      fullWidth
      onClick={onClick}
      disabled={disabled}
      data-test={dataTest}
      endIcon={endIcon}
      onMouseEnter={handleOnMouseEnter}
      size="large"
      isLoading={isLoading}
    >
      {/* Label */}
      <Box sx={{ zIndex: 1, position: "relative" }}>{label}</Box>
      {/* Animation, disabled in cypress test environment */}
      {showAnimation && heartsAnimation && !window?.Cypress && (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: 0,
            pointerEvents: "none",
            opacity: 0.5,
          }}
          key={animationKey}
        >
          <Lottie
            options={{
              animationData: heartsAnimation,
              autoplay: true,
              loop: shouldLoop,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            isClickToPauseDisabled={true}
            width="100%"
            height="100%"
            speed={0.7}
          />
        </Box>
      )}
    </Button>
  );
};
