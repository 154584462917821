import { FC } from "react";

import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";

import { useIsFormV2 } from "../../features/FormV2/hooks/useIsFormV2";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useTranslate, Trans } from "../../hooks/useTranslate";
import { Typography } from "../design-system/Typography";
import { DisplayWithInfoFormPreview } from "../InfoForm/InfoForm";

export const FormZeffyDescription: FC<{ isTicketing?: boolean }> = ({ isTicketing = false }) => {
  const { t } = useTranslate();
  const theme = useTheme();
  const isFormV2 = useIsFormV2();
  const { isPhoneScreen } = useMediaQuery();

  // We'll inline the svg in order to set a different branded fill color on the bulb and lines
  const inlineSvg = (
    <svg
      width={isFormV2 ? "24px" : isPhoneScreen ? "24px" : "36px"}
      height={isFormV2 ? "24px" : isPhoneScreen ? "24px" : "36px"}
      viewBox="0 0 48 48"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3748 40.025H21.5498V40.7998C21.5498 40.8998 21.6248 40.9998 21.7498 40.9998H26.1748C26.2748 40.9998 26.3748 40.9248 26.3748 40.7998V40.025ZM19.5498 39.8225C18.5223 39.4084 17.7998 38.4034 17.7998 37.225V32.9C17.7748 32.55 17.4248 28.25 15.0998 25.925L15.0748 25.9C12.8998 23.6 11.6748 20.575 11.6748 17.4C11.6748 10.55 17.2248 5 24.0498 5C30.8748 5 36.4248 10.55 36.4248 17.375C36.4248 20.55 35.2248 23.575 33.0248 25.875L32.9998 25.9C30.7861 28.1137 30.2231 32.0649 30.1163 32.8141L30.1163 32.8143C30.1086 32.8688 30.1032 32.9063 30.0998 32.925V33.025V37.2C30.1188 38.3973 29.4011 39.4067 28.3748 39.8221V40.7998C28.3748 42.0248 27.3748 42.9998 26.1748 42.9998H21.7498C20.5498 42.9998 19.5498 42.0248 19.5498 40.7998V39.8225ZM28.1436 33.8496H19.7998V37.2C19.7998 37.65 20.1498 38 20.5998 38H27.3248C27.7748 38 28.1248 37.65 28.1248 37.2L28.1436 33.8496ZM19.7061 31.8496H28.2762C28.5533 30.2151 29.3752 26.7377 31.5998 24.475C33.4248 22.55 34.4248 20 34.4248 17.35C34.4248 11.625 29.7748 6.975 24.0498 6.975C18.3248 6.975 13.6748 11.625 13.6748 17.35C13.6748 20.05 14.6748 22.575 16.5248 24.5C18.7475 26.7416 19.48 30.1895 19.7061 31.8496ZM30.3748 18.1748C30.3748 18.7248 30.8248 19.1748 31.3748 19.1748C31.9248 19.1748 32.3748 18.7248 32.3748 18.1748C32.3748 13.1998 28.3248 9.1748 23.3748 9.1748C22.8248 9.1748 22.3748 9.6248 22.3748 10.1748C22.3748 10.7248 22.8248 11.1748 23.3748 11.1748C27.2248 11.1748 30.3748 14.3248 30.3748 18.1748Z"
        fill={isFormV2 ? theme.palette.text.form.moderate : theme.palette.text.form.quiet}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.025 13.3496C6.85 13.3496 6.675 13.2996 6.525 13.2246L2.6 10.9246C2.125 10.6496 1.95 10.0246 2.25 9.54955C2.525 9.07455 3.15 8.92455 3.625 9.19955L7.55 11.4996C8.025 11.7746 8.2 12.3996 7.9 12.8746C7.7 13.1746 7.375 13.3496 7.025 13.3496ZM40.175 12.8999C40.375 13.2249 40.7 13.3999 41.05 13.3999C41.225 13.3999 41.4 13.3749 41.55 13.2499L45.475 10.9499C45.95 10.6749 46.1 10.0499 45.825 9.57494C45.55 9.09994 44.925 8.94994 44.45 9.22494L40.525 11.5249C40.05 11.7999 39.9 12.4249 40.175 12.8999ZM41.05 18.3252H45.075C45.625 18.3252 46.075 17.8752 46.075 17.3252C46.075 16.7752 45.625 16.3252 45.075 16.3252H41.05C40.5 16.3252 40.05 16.7752 40.05 17.3252C40.05 17.8752 40.5 18.3252 41.05 18.3252ZM44.975 25.5497C44.8 25.5497 44.625 25.4997 44.475 25.4247L40.55 23.1247C40.075 22.8497 39.9 22.2247 40.2 21.7497C40.475 21.2747 41.1 21.1247 41.575 21.3997L45.5 23.6997C45.975 23.9747 46.15 24.5997 45.85 25.0747C45.65 25.3747 45.325 25.5497 44.975 25.5497ZM3 18.2744H7.025C7.575 18.2744 8.025 17.8244 8.025 17.2744C8.025 16.7244 7.575 16.2744 7.025 16.2744H3C2.45 16.2744 2 16.7244 2 17.2744C2 17.8244 2.45 18.2744 3 18.2744ZM2.225 24.9742C2.425 25.2992 2.75 25.4742 3.1 25.4742C3.275 25.4742 3.425 25.4242 3.6 25.3242L7.525 23.0242C8 22.7492 8.15 22.1242 7.875 21.6492C7.6 21.1742 6.975 21.0242 6.5 21.2992L2.575 23.5992C2.1 23.8742 1.95 24.4992 2.225 24.9742Z"
        fill={theme.palette.text.form.quiet}
      />
    </svg>
  );

  return (
    <Grid container justifyContent="center" sx={{ margin: 0 }}>
      <DisplayWithInfoFormPreview content={t(isTicketing ? "ticketing" : "donationForm", "preview.savingEveryCents")}>
        <Grid
          item
          xs={12}
          sx={{
            margin: 0,
            backgroundColor: isFormV2 ? theme.palette.surface.form.quiet : theme.palette.surface.neutral.supershy,
            paddingBlock: theme.spacing(1.5),
            paddingInline: theme.spacing(2),
            borderRadius: theme.radius(1),
          }}
        >
          <Grid
            container
            gap={1.5}
            alignItems={isFormV2 ? (isPhoneScreen ? "flex-start" : "center") : "flex-start"}
            flexDirection="row"
          >
            <Grid item sx={{ lineHeight: 0 }}>
              {inlineSvg}
            </Grid>
            <Grid item xs>
              <Typography variant="body2" align="left" vibe="text-form-moderate" component="span">
                <Trans
                  space="common"
                  i18nKey={isTicketing ? "zeffyTipDisclaimerTicketing" : "zeffyTipDisclaimerDonation"}
                  components={{ sb: <Typography variant="subtitle2" vibe="text-form-moderate" component="span" /> }}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DisplayWithInfoFormPreview>
    </Grid>
  );
};
