import { FC, useContext } from "react";

import { Stack } from "@mui/material";

import { FrontendTicketingContext } from "../../../../contexts/FrontendTicketingContext";
import { STEP_TO_ELEMENT_ID } from "../../../LiveFormEditor/AnimatePreview/Arrow/ArrowContext";
import { GHOST_PREVIEW_OPACITY } from "../../../LiveFormEditor/LivePreview/ghostPreview.constant";
import { useIsGhostPreview } from "../../../LiveFormEditor/LivePreview/useIsGhostPreview";

import { TicketingV2DateSummarySquare } from "./TicketingV2DateSummarySquare";
import { TicketingV2DateSummaryText } from "./TicketingV2DateSummaryText";

export const TicketingV2DateSummary: FC = () => {
  const { selectedOccurrenceWithRates, ticketingIsClosed } = useContext(FrontendTicketingContext);

  const occurrence = selectedOccurrenceWithRates?.occurrence;
  const start = occurrence?.startUtc;
  const end = occurrence?.endUtc;

  const isGhostPreview = useIsGhostPreview("TicketingV2DateSummary");

  if (!start || !end) {
    return null;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      sx={{
        ...(isGhostPreview && {
          opacity: GHOST_PREVIEW_OPACITY,
        }),
      }}
      data-test="ticketing-v2-date-summary"
      id={STEP_TO_ELEMENT_ID["date"]}
    >
      <TicketingV2DateSummarySquare start={start} sx={{ flexShrink: 0 }} />
      <TicketingV2DateSummaryText start={start} end={end} ticketingIsClosed={ticketingIsClosed} sx={{ flexGrow: 1 }} />
    </Stack>
  );
};
