import { useEffect, useState, useRef } from "react";

export const useHasValueChanged = <T>(value: T) => {
  const valueRef = useRef(value);
  const [state, setState] = useState<{ hasChanged: boolean; value: T }>({ hasChanged: false, value });

  useEffect(() => {
    if (valueRef.current !== value) {
      setState({ hasChanged: true, value });
    } else {
      setState((s) => ({ ...s, value }));
    }
  }, [value]);

  return [state.hasChanged, state.value];
};
